<template>
  <div
    class="c-unit-tile"
    :class="[`c-unit-tile--${slug}`]"
    @click="$router.push(`/search?unit=${slug}`)"
  >
    <VHeading header="h2" class="c-unit-tile__title" :text="title" />
    <div class="c-unit-tile__bg" :style="`background-image: url(${cover});`" />
  </div>
</template>

<script>
import { computed } from "@vue/composition-api";
import VHeading from "@forlagshuset/v-heading";

export default {
  components: {
    VHeading,
  },

  methods: {
    toggleTile() {
      this.isOpened = !this.isOpened;
    },
  },

  props: {
    title: String,
    slug: String,
    index: Number,
    cover: String,
  },

  data() {
    return {
      isOpened: false,
    };
  },

  setup(props, ctx) {
    const isTeacher = computed(
      () => ctx.root.$store.getters["appConfig/isTeacher"]
    );

    return {
      isTeacher,
    };
  },
};
</script>

<style lang="scss">
.c-unit-tile {
  display: flex;
  flex-flow: column;
  cursor: pointer;
  border-radius: 6px;
  overflow: hidden;

  &__title {
    font-family: $headers-font-family;
    max-height: 2.25rem;
    padding-left: 2.25rem;
    color: $color-white;
    transition: all 750ms;
    h2 {
      margin: 0;
      padding: 0;
      line-height: 2.25rem;
      font-weight: normal;
    }
  }

  &__bg {
    cursor: pointer;
    width: 100%;
    height: 100%;
    transition: all 750ms;
  }

  &--echo-1-identity {
    .c-unit-tile__title {
      background-color: $unit-identity-bg;
    }
    .c-unit-tile__bg {
      background-position-x: 22%;
      background-position-y: 58%;
      background-size: 150%;
    }

    &:hover {
      .c-unit-tile__title {
        background-color: $unit-identity-bg-dark;
      }

      .c-unit-tile__bg {
        background-position-x: 36%;
        background-position-y: 72%;
        background-size: 167%;
      }
    }
  }

  &--echo-2-english-around-the-world {
    .c-unit-tile__title {
      background-color: $unit-english-bg;
    }

    .c-unit-tile__bg {
      background-position-y: 40%;
      background-position-x: 75%;
      background-size: 130%;
    }
    &:hover {
      .c-unit-tile__title {
        background-color: $unit-english-bg-dark;
      }

      .c-unit-tile__bg {
        background-position-y: 42%;
        background-position-x: 75%;
        background-size: 150%;
      }
    }
  }

  &--echo-3-citizenship {
    .c-unit-tile__title {
      background-color: $unit-citizenship-bg;
    }

    .c-unit-tile__bg {
      background-position-x: 62%;
      background-size: 82%;
      background-position-y: 37%;
    }

    &:hover {
      .c-unit-tile__title {
        background-color: $unit-citizenship-bg-dark;
      }

      .c-unit-tile__bg {
        background-position-x: 62%;
        background-size: 92%;
        background-position-y: 28%;
      }
    }
  }

  &--echo-4-indigenous-peoples {
    .c-unit-tile__title {
      background-color: $unit-indigenous-bg;
    }

    .c-unit-tile__bg {
      background-position-x: 86%;
      background-position-y: -165%;
      background-size: 115%;
    }

    &:hover {
      .c-unit-tile__title {
        background-color: $unit-indigenous-bg-dark;
      }

      .c-unit-tile__bg {
        background-position-x: 62%;
        background-position-y: -133%;
        background-size: 125%;
      }
    }
  }

  &--echo-my-grammar {
    .c-unit-tile__title {
      background-color: $unit-grammar-bg;
    }

    .c-unit-tile__bg {
      background-position-x: 36%;
      background-position-y: 22%;
      background-size: 125%;
    }

    &:hover {
      .c-unit-tile__title {
        background-color: $unit-grammar-bg-dark;
      }

      .c-unit-tile__bg {
        background-position-x: 42%;
        background-position-y: 35%;
        background-size: 145%;
      }
    }
  }
}
</style>
