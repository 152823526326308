import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import auth from '@/auth'
import store from '@/store'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      hideHeader: true,
      hideTopbar: true
    }
  },
  {
    path: '/unit/:unitSlug?',
    name: 'Unit',
    component: () => import(/* webpackChunkName: "unit" */ '../views/Unit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lesson/:lessonSlug',
    name: 'Lesson',
    component: () => import(/* webpackChunkName: "lesson" */ '../views/Lesson.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/audio/:unitSlug/',
    name: 'Audiobook',
    component: () => import(/* webpackChunkName: "audiobook" */ '../views/Audiobook.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/audio/:unitSlug/:audioSlug',
    name: 'Audio LE',
    component: () => import(/* webpackChunkName: "audiobook" */ '../views/AudioLE.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/video/:unitSlug/:activitySlug',
    name: 'Activity',
    component: () => import(/* webpackChunkName: "video" */ '../views/Activity.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/text/:unitSlug/:textSlug',
    name: 'Article',
    component: () => import(/* webpackChunkName: "article" */ '../views/Article.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/fagstoff/:lesson',
    name: 'Guide',
    component: () => import('../views/TeachersGuide.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/results/:phrase?',
    name: 'SearchResults',
    component: () => import('../views/Results.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  if (window.localStorage.getItem('hasStudentAccess')) {
    return next();
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['appConfig/hasCheckedAccess']) {
      try {
        const user = await auth.checkToken()

        store.dispatch('user/setToken')
        store.dispatch('user/setUser', user)
        store.dispatch('appConfig/setCheckedAccess', true)
        if (to.name === 'Home' && store.getters['appConfig/isTeacher']) {
          next('/search')
        }

        next()
      } catch {
        next({path: '/login'})
      }
    } else {
      next({path: '/login'})
    }
  } else {
    next()
  }
})

export default router
