const getCourse = state => {
  const content = state.course

  return content
}

const getCourseUnits = state => {
  const content = state.units

  return content
}

const getUnit = state => slug => {
  const content = state.units.filter(unit => unit.slug === slug)[0]
  
  return content
}

const getText = state => slug => {
  const content = state.units.filter(unit => unit.slug === slug)[0].text.map(t => {
    t.parentUnit = slug

    return t
  })
  
  return content
}

const getVideo = state => slug => {
  const content = state.units.filter(unit => unit.slug === slug)[0].video.map(v => {
    v.parentUnit = slug

    return v
  })
  
  return content
}

const getLessonPlans = state => slug => {
  const content = state.units.filter(unit => unit.slug === slug)[0].lessonPlans
  
  return content
}

const getAudiobook = state => slug => {
  const content = state.units.filter(unit => unit.slug === slug)[0].audiobook.map(a => {
    a.parentUnit = slug

    return a
  })

  return content
}

const getAllText = state => {
  const content = []

  state.units.forEach(u => {
    if (!u.teacherUnit) {
      content.push(...u.text.map(t => {
        t.parentUnit = u.slug

        return t
      }))
    }
  })

  return content
}

const getAllVideo = state => {
  const content = []

  state.units.forEach(u => {
    if (!u.teacherUnit) {
      content.push(...u.video.map(v => {
        v.parentUnit = u.slug

        return v
      }))
    }
  })

  return content
}

const getAllAudiobook = state => {
  const content = []

  state.units.forEach(u => {
    if (!u.teacherUnit) {
      content.push(...u.audiobook.map(a => {
        a.parentUnit = u.slug

        return a
      }))
    }
  })

  return content
}

const getAllLessonPlans = state => {
  const content = []

  state.units.forEach(u => {
    if (!u.teacherUnit) {
      content.push(...u.lessonPlans)
    }
  })

  return content
}

export default {
  getCourse,
  getCourseUnits,
  getUnit,
  getText,
  getVideo,
  getLessonPlans,
  getAudiobook,
  getAllText,
  getAllVideo,
  getAllAudiobook,
  getAllLessonPlans
}
  