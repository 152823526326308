const SET_USER_ROLE = (state, role) => {
  state.userRole = role
}

const SET_CHECKED_ACCESS = (state, checked) => {
  state.checkedAccess = checked  
}

export default {
  SET_USER_ROLE,
  SET_CHECKED_ACCESS
}
