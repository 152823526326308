import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/i18n/en'
import nb from '@/i18n/nb'
import nn from '@/i18n/nn'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'nb',
  messages: {
    nb,
    nn,
    en
  }
})

export default i18n
