<template>
  <div class="c-login u-mya u-mla">
    <TheButton
      defaultStyles
      type="button"
      class="c-login__btn"
      @click.native="showMenu = !showMenu"
    >
      {{ userName }}
      <TeacherIcon v-if="isTeacher" class="c-login__icon" fill="#000" />
      <StudentIcon v-else class="c-login__icon" fill="#000" />
    </TheButton>
    <div v-show="showMenu" class="c-login__menu">
      <TheButton
        defaultStyles
        class="c-login__btn-logout"
        @click.native="logout"
      >
        {{ $t('LOGIN_LOGOUT') }}
      </TheButton>
    </div>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import TeacherIcon from '@/assets/svg/Icon_User_Teacher'
import StudentIcon from '@/assets/svg/Icon_User_Student'
import TheButton from '@forlagshuset/v-button'

export default {
  components: {
    TeacherIcon,
    StudentIcon,
    TheButton,
  },

  methods: {
    logout() {
      if (window.localStorage.getItem('hasStudentAccess')) {
        window.localStorage.clear()
        this.$router.push('/login')
      } else {
        this.$root.$store.dispatch('user/logoutUser')
      }
    },
  },

  setup(props, vm) {
    const showMenu = ref(false)
    const userName = computed(() => vm.root.$store.getters['user/userName'])
    const isTeacher = computed(
      () => vm.root.$store.getters['appConfig/isTeacher'],
    )

    return {
      isTeacher,
      showMenu,
      userName,
    }
  },
}
</script>

<style lang="scss">
.c-login {
  position: relative;
  display: none;

  @include bp($mobile-topbar-breakpoint) {
    display: block;
  }

  .c-nav-mobile & {
    display: block;
  }
}
.c-login__btn {
  display: flex;
  background: none;
  border: 0;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
  background-color: $topbar-bg !important;
  border: none !important;
}
.c-login__icon {
  height: 1.5rem;
  margin-left: 0.5rem;
}
.c-login__menu {
  position: absolute;
  right: 0;
  font-weight: 700;
  z-index: 999999;
}
</style>
