<template>
  <div v-if="!$route.meta.hideTopbar" class="c-search__container">
    <Searchbox
      search-label=""
      :search-form-label="$t('SEARCH_FORM_LABEL')"
      :search-input-label="$t('SEARCH_INPUT_LABEL')"
      :search-placeholder="$t('SEARCH_PLACEHOLDER')"
      :search-button="$t('SEARCH_BUTTON')"
      @submit-search="onSubmitSearch"
      @clear-search="onClearSearch"
      @click.native="term ? onSubmitSearch(term) : null"
      :characters="limit"
    />
    <div
      v-if="showAuto && term"
      class="c-search__autocomplete"
      v-click-outside="onClickOutside"
    >
      <SearchCounter
        class="c-results__counter u-pt u-px"
        v-if="term"
        :term="term"
        :count="count"
      >
        {{ $t('SEARCH_COUNT_TEXT') }}
      </SearchCounter>

      <div class="c-results__wrapper u-px">
        <SearchResults
          v-if="term"
          class="c-results__results"
          :term="term"
          :contentTypes="contentTypes"
          :searchBaseURL="searchBaseURL"
          :searchQuery="query"
          :indexName="indexName"
          :units="units"
          :limit="limit"
          @on-result-count="onResultCount"
          @click.native="showAuto = !showAuto"
        />
      </div>

      <FHButton
        v-if="count && count > limit"
        defaultStyles
        :href="`/results/${term}`"
        class="c-results__more"
      >
        {{ $t('SEARCH_SHOW_MORE') }} "{{ term }}"
      </FHButton>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import Searchbox from '@forlagshuset/v-searchbox'
import SearchCounter from '@forlagshuset/v-search-counter'
import SearchResults from '@forlagshuset/v-search-results/FHSearchResults'
import searchQuery from '@forlagshuset/v-search-results/services/es'
import FHButton from '@forlagshuset/v-button'

export default {
  name: 'SearchContainer',

  components: {
    Searchbox,
    SearchCounter,
    SearchResults,
    FHButton,
  },

  setup(props, ctx) {
    const isTeacher = computed(
      () => ctx.root.$store.getters['appConfig/isTeacher'],
    )
    const limit = ref(3)
    const showAuto = ref(false)
    const term = ref('')
    const count = ref(0)
    const contentTypes = computed(() =>
      isTeacher.value
        ? ['lesson', 'teacher_lesson', 'audio', 'activity', 'article']
        : ['audio', 'activity', 'article'],
    )
    const units = ref([])
    const searchBaseURL = computed(() => process.env.VUE_APP_ES_URL)
    const indexName = ref('strapi_en_gb')
    const query = computed(() => searchQuery(term.value, contentTypes.value))

    onMounted(async () => {
      await ctx.root.$store.dispatch(
        'content/setCourseUnits',
        process.env.VUE_APP_APPSLUG,
      )
      units.value = ctx.root.$store.getters['content/getCourseUnits']
    })

    const onSubmitSearch = e => {
      if (e !== '') {
        if (ctx.root.$router.history.current.name === 'SearchResults') {
          //search for phrase
          ctx.root.$store.dispatch('search/setPhrase', e)
          //update router
          ctx.root.$router.push({ params: { phrase: e } })
        } else {
          term.value = e
          showAuto.value = true
        }
      } else {
        term.value = e
        ctx.root.$store.dispatch('search/setPhrase', e)
        showAuto.value = false
      }
    }

    const onResultCount = e => {
      count.value = e
    }

    const onClearSearch = () => {
      count.value = 0
      term.value = ''
      ctx.root.$store.dispatch('search/setPhrase', '')
      showAuto.value = false
    }

    const onClickOutside = () => {
      showAuto.value = false
    }

    return {
      limit,
      term,
      showAuto,
      count,
      contentTypes,
      units,
      searchBaseURL,
      indexName,
      query,
      onSubmitSearch,
      onResultCount,
      onClearSearch,
      onClickOutside,
      isTeacher,
    }
  },
}
</script>

<style lang="scss">
.c-search__container {
  background: $searchbox-bg;
  width: 100%;
  position: relative;
  padding: 1.5rem 0;
  .c-searchbox {
    display: flex;
    align-items: center;
    justify-items: center;
    width: 22rem;
    max-width: calc(100% - 2rem);
    margin: 0 auto;
  }

  .c-searchbox__textfield {
    width: 100%;
  }
  .c-searchbox__input {
    width: calc(100% - 2.75rem);
  }
  .c-searchbox__button {
    width: 3rem;
    height: 2.25rem;
    font-size: 0;
    background-color: $search-button-bg;
    background-image: url('/img/search-icon.svg');
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: 75% auto;
    border: 1px solid $color-black;
  }
  .c-searchbox__cross-button {
    top: 0.5rem;
  }

  .c-search__autocomplete {
    position: absolute;
    z-index: 99;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 19.25rem;
    width: 75%;
    left: 0;
    right: 2.5rem;
    margin: auto;
    background-color: $color-white;
    border: 1px solid $color-black;
    @include bp-down($small) {
      width: calc(22rem - 2px);
      max-width: calc(100% - 2rem);
      right: 0;
    }
  }
}
.c-results__results > div {
  display: flex;
  flex-direction: column;
}

.c-search-result {
  color: $search-results-color;
  margin: 0;
  padding: 1.25rem 0.25rem;
  border-bottom: 1px solid $color-medium-grey;
  text-decoration: none;

  [class^='c-search-result--'] {
    display: flex;
    flex-direction: column;
  }

  .c-search-result__title {
    font-weight: 700;
    background-image: url('/img/lp-icon.svg');
    background-position-x: left;
    background-position-y: top;
    background-repeat: no-repeat;
    background-size: 1.25rem 1.25rem;
    padding-left: 1.75rem;
    line-height: 1.25rem;
    order: 1;

    p {
      margin: 0;
      font-size: 0.875rem;
      line-height: 1.35rem;
    }

    u {
      background-color: $searchbox-bg;
      text-decoration: none;
    }
  }
  .c-search-result__unit {
    margin: 0.25rem 0;
    order: 1;
    font-size: 0.75rem;
    color: $color-green;
  }

  .c-search-result__desc {
    order: 3;
    font-size: 0.875rem;
    line-height: 1.25rem;
    p,
    ol,
    ul,
    li {
      margin: 0;
      padding: 0 !important;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
    u {
      background-color: $searchbox-bg;
      text-decoration: none;
    }
    table,
    td,
    th {
      border: 0;
      display: block;
      padding: 0;
    }
    ul,
    ol {
      list-style: none;
      li:before {
        display: none;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    small,
    b {
      font-size: 0.875rem !important;
      font-weight: normal !important;
    }
  }

  &:hover {
    background-color: rgba($searchbox-bg, 0.33);
  }

  &:only-child {
    border-bottom: 0px;
    margin-bottom: 1rem;
  }

  .c-search-result__type {
    display: none;
  }
}
.c-search-result__title {
  .c-search-result--activity & {
    background-image: url('/img/video-icon.svg');
  }

  .c-search-result--article & {
    background-image: url('/img/text-icon.svg');
  }

  .c-search-result--audio & {
    background-image: url('/img/audio-icon.svg');
  }
}
.c-results__more {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  background-color: transparent;
  text-decoration: underline;
}
</style>
