import { fetchSH } from '@/api'

const fetchCourseBySlug = async courseSlug => {
  const data = await fetchSH('courses/' + courseSlug)

  return data
}

const fetchCourseUnits = async courseSlug => fetchSH('courses/' + courseSlug + '/units')

// new endpoint in use - leave this as fallback
/*const fetchCourseUnits = async unitIds => {
  const units = []
  let preserveOrder = unitIds
  await Promise.all(unitIds.map(async unit => {
    units.push(await fetchSH('units/' + unit))
  }))
  preserveOrder = preserveOrder.map(i => units.filter(l => l.id === i)[0])
  return preserveOrder
}*/

const fetchCourseAllVideoType = courseSlug => fetchSH('courses/' + courseSlug + '/videotype')

const fetchCourseAllAudioType = courseSlug => fetchSH('courses/' + courseSlug + '/audiotype')

const fetchCourseAllArticleType = courseSlug => fetchSH('courses/' + courseSlug + '/articletype')

const fetchCourseAllComponent = (courseSlug, componentName) => fetchSH('courses/' + courseSlug + '/component/' + componentName)

const fetchLessonActivity = courseSlug => fetchSH('courses/' + courseSlug + '/articletype')

const fetchUnitBySlug = async unitSlug => {
  const data = await fetchSH('units/' + unitSlug)

  return data
}

const fetchUnitLessons = async unitSlug => fetchSH('units/' + unitSlug + '/lessons')

// new endpoint in use - leave this as fallback
// const fetchUnitLessons = async lessonsIds => {
//   const lessons = []
//   let preserveOrder = lessonsIds

//   await Promise.all(lessonsIds.map(async lesson => {
//     lessons.push(await fetchSH('lessons/' + lesson))
//   }))
//   preserveOrder = preserveOrder.map(i => lessons.filter(l => l.id === i)[0])

//   return preserveOrder
// }

const fetchLesson = async lessonSlug => {
  const data = await fetchSH('lessons/' + lessonSlug)

  return data
}

const fetchLessonUnit = unitId => fetchSH('units/' + unitId)

const fetchCourseTags = async courseSlug => {
  const course = await fetchCourseBySlug(courseSlug)

  return course.tags
}

export {
  fetchCourseBySlug,
  fetchCourseUnits,
  fetchUnitBySlug,
  fetchUnitLessons,
  fetchLesson,
  fetchLessonUnit,
  fetchCourseAllVideoType,
  fetchCourseAllAudioType,
  fetchCourseAllArticleType,
  fetchCourseAllComponent,
  fetchLessonActivity,
  fetchCourseTags
}
