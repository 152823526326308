import Vue from 'vue'
import Vuex from 'vuex'

import appConfig from './modules/appconfig'
import user from './modules/user'
import content from './modules/content'
import search from './modules/search'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    results: {
      video: new Array(2),
      text: new Array(10),
      lessonPlans: new Array(5),
      audio: []
    },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    appConfig,
    user,
    content,
    search
  }
})
