<template>
  <div class="c-home">
    <div
      v-if="description"
      class="c-home__description u-mx u-my u-py u-px--3"
      v-md-html="description"
    ></div>
    <VHeading
      header="h2"
      class="c-home__header  u-my-2 u-tac"
      :text="$t('HOME_UNITS_HEADER')"
    />
    <div class="c-home__units">
      <UnitTile
        v-for="(unit, i) in units"
        class="c-home__units-item"
        :key="unit._id"
        :title="unit.name"
        :slug="unit.slug"
        :index="i"
        :cover="unit.coverSrc"
      />
    </div>
    <VHeading
      header="h2"
      class="c-home__header c-home__header--tools u-tac"
      :text="$t('HOME_TOOLS_HEADER')"
    />
    <div class="c-home__tools u-my">
      <a
        v-for="tool in tools"
        class="c-home__tools-item"
        :key="tool.link"
        :href="tool.link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          class="c-home__tools-item--image"
          :style="`background-image: url('${tool.image}');`"
        ></div>
        <div class="c-home__tools-item--title">{{ $t(tool.titleLoc) }}</div>
        <div class="c-home__tools-item--description">
          {{ $t(tool.descriptionLoc) }}
        </div>
      </a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { ref, onMounted } from '@vue/composition-api'
import { addCloudinaryUrlParams } from '@/utils'
import UnitTile from '@/components/UnitTile'
import VHeading from '@forlagshuset/v-heading'
import config from '@/config'

export default {
  name: 'Home',

  components: {
    UnitTile,
    VHeading,
  },

  setup(props, ctx) {
    const units = ref([])
    const appSlug = process.env.VUE_APP_APPSLUG
    const description = ref('')
    const tools = ref(config.tools)

    onMounted(async () => {
      await ctx.root.$store.dispatch('content/setCourse', appSlug)
      const course = ctx.root.$store.getters['content/getCourse']

      description.value = course.description

      await ctx.root.$store.dispatch('content/setCourseUnits', appSlug)
      units.value = ctx.root.$store.getters['content/getCourseUnits'].filter(
        unit => !unit.teacherUnit,
      )

      const croppingParams = {
        'echo-1-identity': 'c_crop,x_629,w_1606,y_1400,h_770/a_355',
        'echo-2-english-around-the-world':
          'c_crop,x_1075,w_526,y_953,h_526/a_90',
        'echo-3-citizenship': 'c_crop,x_531,w_1241,y_466,h_732',
        'echo-4-indigenous-peoples': 'c_crop,x_0,w_2686,y_2425,h_1055',
        'echo-my-grammar': 'c_crop,x_150,w_1530,y_466,h_1055',
      }

      units.value.forEach(unit => {
        if (unit.cover && unit.cover.image_type) {
          const coverURL = unit.cover.image_type.file.url
          const param = croppingParams[unit.slug]
          unit.coverSrc = addCloudinaryUrlParams(coverURL, param)
        }
      })
    })

    return {
      units,
      tools,
      appSlug,
      description,
    }
  },
}
</script>

<style lang="scss">
.c-home {
  min-height: calc(100vh - 12.5rem);
  padding: 0 1rem;

  &__description {
    background-color: $searchbox-bg;
  }

  &__units {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    max-width: $medium;
    margin: auto;

    &-item {
      width: 100%;
      max-width: 330px;
      height: 120px;
      margin: 0;
    }
  }
  &__header--tools {
    margin-top: 3rem;
  }

  &__tools {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    max-width: $medium;
    margin: auto;

    &-item {
      width: 100%;
      max-width: 310px;
      height: 100px;
      margin: 0;
      display: grid;
      grid-template-columns: 100px 1fr;
      grid-template-rows: 24px 1fr;
      gap: 2px 10px;
      text-decoration: none;
      color: $color-black;
      background-color: $tool-tile-bg;
      padding: 10px;

      &--image {
        grid-area: 1 / 1 / 3 / 2;
        background-size: cover;
      }
      &--title {
        grid-area: 1 / 2 / 2 / 3;
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 24px;
      }
      &--description {
        grid-area: 2 / 2 / 3 / 3;
      }
    }
  }
}
</style>
