const setUserRole = ({ commit }, role) => {
  commit('SET_USER_ROLE', role)
}

const setCheckedAccess = ({ commit }, checked) => {
  commit('SET_CHECKED_ACCESS', checked)
}

export default {
  setUserRole,
  setCheckedAccess
}
