const en = {
  LANGUAGE_CODE: 'en_GB',
  LANGUAGE_NAME: 'English',
  WEEK: 'weeks',
  LESSON_HOUR: 'lessons',
  MINUTE: 'minutes',
  CHAPTER_INFO: 'Chapter info',
  CONTENT: 'Library',
  LESSON_PLANS: 'Lesson plans',
  TEXT: 'Text',
  VIDEO: 'Video',
  AUDIO_BOOK: 'Audio',
  NO_RESULTS: 'More content will be published soon.',
  UNIT_LESSON_PLANS: 'Lesson plans',
  UNIT_CONTENT: 'Content',
  LESSON_COMPETENCY_AIMS: 'Competence aims',
  HAMBURGER_MENU: 'Menu',
  LOGIN_LOGOUT: 'Logout',
  LOGIN_BUTTON: 'Lærer (innlogging)',
  LOGIN_BUTTON_STUDENT: 'Elev',
  LOGIN_HEADING: 'Velkommen til Echo',
  LOGIN_TEXT: 'Engelsk for ungdomstrinnet',
  LOGIN_BOTTOM_TEXT:
    'Ønsker du å lære mer om Echo og hvordan du<br> kan jobbe med de nye læreplanene i engelsk?',
  LOGIN_READ_MORE_TEXT: 'Read more and get access',
  CONTENT_VIDEO: 'Video',
  CONTENT_AUDIO: 'Audio',
  CONTENT_TEXT: 'Text',
  FILTERS_CLEAR_ALL: 'Clear all',
  FILTERS_HEADING: 'Filters',
  FILTERS_HEADING_THEMES: 'Themes',
  FILTERS_HEADING_LESSON_PLANS: 'Didactic filters',
  VIDEO_TRANSCRIPT: 'Link to transcript',
  SEARCH_COUNT_TEXT: 'hits for',
  SEARCH_PLACEHOLDER: 'Search',
  SEARCH_FORM_LABEL: 'search form',
  SEARCH_INPUT_LABEL: 'search form input',
  SEARCH_BUTTON: ' ',
  SEARCH_LABEL_LESSON: 'Lesson plans',
  SEARCH_LABEL_TEACHER_UNIT: 'Teacher guides',
  SEARCH_LABEL_TEACHER_LESSON: 'Teacher’s guide',
  SEARCH_LABEL_AUDIO: 'Audio',
  SEARCH_LABEL_ACTIVITY: 'Videos',
  SEARCH_LABEL_ARTICLE: 'Texts',
  SEARCH_NO_RESULTS: 'No results found',
  SEARCH_SHOW_MORE: 'See all results for',
  SEARCH_LABEL_ALL: 'All',
  HOME_UNITS_HEADER: 'Choose a theme',
  SEARCH_SIDE_NAV_TITLE: 'Themes',
  HOME_TOOLS_HEADER: 'Or use one of our tools',
  SPEAK_UP: 'Speak up!',
  SPEAK_UP_DESC: 'Improve your oral skills.',
  WRITE_ON: 'Write on!',
  WRITE_ON_DESC: 'Improve your written skills.',
}

export default en
