import { fetchSH } from '@/api'
import { fetchCourseBySlug, fetchCourseUnits, fetchUnitLessons } from '@/services/strapi'

const setCourse = async ({ state, commit }, appSlug) => {
  if (Object.keys(state.course).length > 0) {
    return
  }

  const course = await fetchCourseBySlug(appSlug)

  commit('SET_COURSE', course)

  return course
}

const setCourseUnits = async ({ state, commit }, appSlug) => {
  if (state.units.length > 0) {
    return
  }

  const units = await fetchCourseUnits(appSlug)

  commit('SET_COURSE_UNITS', units)

  return units
}

const setLessonPlans = async ({ state, commit }, slug) => {
  let unitHasLessonPlans = false

  state.units.filter(u => {
    if (u.slug === slug) {
      if ('lessonPlans' in u) {
        unitHasLessonPlans = true
      }
    }
  })
  if (unitHasLessonPlans === true) {
    return
  }

  const lessonPlans = await fetchUnitLessons(slug)
  
  commit('SET_LESSON_PLANS', { lessonPlans, slug })

  return lessonPlans
}

const setNewTag = async ({ state, commit }, {newTagId, slug}) => {
  let unitHasNewTag = false

  state.units.filter(u => {
    if (u.slug === slug) {
      if ('audiobook', 'video', 'text' in u) {
        unitHasNewTag = true
      }
    }
  })
  if (unitHasNewTag === true) {
    return
  }

  const newTag = await fetchSH('tag-types/' + newTagId)
  
  commit('SET_NEW_TAG', { newTag, slug })

  return newTag
}

export default {
  setCourse,
  setCourseUnits,
  setLessonPlans,
  setNewTag,
}