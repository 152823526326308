<template>
  <div>
    <div class="c-topbar u-px" v-if="!$route.meta.hideTopbar">
      <img
        v-if="showBack"
        @click="goBack()"
        class="c-topbar__back"
        alt="back"
        src="/img/back.svg"
      />
      <router-link :to="{ name: 'Home' }">
        <img class="c-topbar__logo" alt="Echo logo" src="../assets/logo.png" />
      </router-link>
      <Nav v-if="!$route.meta.hideHeader" />
      <Login v-if="!$route.meta.hideHeader" />
      <Hamburger
        @click.native="toggleNavMobile"
        :isOpened="isNavMobileOpened"
      />
      <NavMobile
        :isUserLogged="true ? true : false"
        :isOpened="isNavMobileOpened"
        @hideNavMobile="hideNavMobile"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import Nav from '@/components/Nav'
import NavMobile from '@/components/NavMobile'
import Login from '@/components/Login'
import Hamburger from '@/components/Hamburger'

export default {
  components: {
    Nav,
    Login,
    Hamburger,
    NavMobile,
  },

  setup(props, ctx) {
    const isNavMobileOpened = ref(false)
    const showBack = computed(() =>
      ctx.root.$route.name === 'Home' ||
      (ctx.root.$store.getters['appConfig/isTeacher'] &&
        ctx.root.$route.name === 'Search')
        ? false
        : true,
    )

    const toggleNavMobile = () => {
      isNavMobileOpened.value = !isNavMobileOpened.value
      document.documentElement.style.overflow = isNavMobileOpened.value
        ? 'hidden'
        : 'auto'
      window.scrollTo(0, 0)
    }

    const goBack = () => {
      ctx.root.$route.name === 'Search'
        ? ctx.root.$router.push({ name: 'Home' })
        : ctx.root.$router.back()
    }

    const hideNavMobile = () => {
      isNavMobileOpened.value = false
    }

    return {
      isNavMobileOpened,
      toggleNavMobile,
      hideNavMobile,
      showBack,
      goBack,
    }
  },
}
</script>

<style lang="scss">
.c-topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $topbar-bg;
}
.c-topbar__logo {
  height: 3rem;
  margin-top: 4px;
  @include bp-down($mobile-topbar-breakpoint) {
    position: absolute;
    left: 0;
    right: 0;
    top: 4px;
    margin: 0 auto;
  }
}
.c-topbar__back {
  max-width: 44px;
  cursor: pointer;
  @include bp($mobile-topbar-breakpoint) {
    display: none;
  }
}
</style>
