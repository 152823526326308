<template>
  <nav
    class="c-nav-mobile"
    :class="{ 'c-nav-mobile--open': isOpened }"
    @click="hidePanel"
  >
    <div class="c-nav-mobile__wrapper">
      <div class="c-logo-mobile">
        <router-link :to="{ name: 'Home' }" class="c-logo-mobile__link">
          <img
            class="c-logo-mobile__img"
            alt="Echo logo"
            src="../assets/logo.png"
          />
        </router-link>
      </div>
      <ul class="c-nav-mobile__list" v-if="!isTeacher">
        <li
          class="c-nav-mobile__item c-nav-mobile__item--unit"
          v-for="(unit, unitId) in units"
          :class="`c-nav-mobile__item--unit-${unitId + 1}`"
          :key="unit._id"
        >
          <router-link
            :to="`/search?unit=${unit.slug}`"
            class="c-nav-mobile__link"
          >
            {{ unit.name }}
          </router-link>
        </li>
      </ul>
      <ul class="c-nav-mobile__list">
        <li class="c-nav-mobile__item">
          <router-link
            to="/text/echo-welcome-to-echo/welcome-to-echo"
            class="c-nav-mobile__link"
          >
            About Echo
          </router-link>
        </li>
        <li v-if="isTeacher" class="c-nav-mobile__item">
          <router-link
            to="/fagstoff/echo-teachers-guide-part-1"
            class="c-nav-mobile__link"
          >
            Teacher’s guide
          </router-link>
        </li>
        <li class="c-nav-mobile__item">
          <a
            href="https://reader.dbok.no/#/book/627a65d86d10090015854ce8"
            target="_blank"
            rel="noopener noreferrer"
            class="c-nav-mobile__link"
          >
            D-book <i>Mini-grammar</i>
          </a>
        </li>
        <li class="c-nav-mobile__item">
          <a
            href="https://reader.dbok.no/#/book/5e87168a190aed001951eb91"
            target="_blank"
            rel="noopener noreferrer"
            class="c-nav-mobile__link"
          >
            D-book <i>Texts in English</i>
          </a>
        </li>
        <li class="c-nav-mobile__item">
          <a
            href="https://speakup.fagbokforlaget.no/"
            target="_blank"
            rel="noopener noreferrer"
            class="c-nav-mobile__link"
          >
            {{ $t('SPEAK_UP').toUpperCase() }}
          </a>
        </li>
        <li class="c-nav-mobile__item">
          <a
            href="https://writeon.app.fagbokforlaget.no/"
            target="_blank"
            rel="noopener noreferrer"
            class="c-nav-mobile__link"
          >
            {{ $t('WRITE_ON').toUpperCase() }}
          </a>
        </li>
        <li class="c-nav-mobile__item c-nav-mobile__item--login">
          <Login />
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { onMounted, computed, watch, ref } from '@vue/composition-api'
import Login from '@/components/Login'

export default {
  components: {
    Login,
  },

  props: {
    isOpened: Boolean,
  },

  setup(props, ctx) {
    const appSlug = process.env.VUE_APP_APPSLUG
    const units = ref([])
    const isTeacher = computed(
      () => ctx.root.$store.getters['appConfig/isTeacher'],
    )
    const userName = computed(() => ctx.root.$store.getters['user/userName'])

    const hidePanel = ev => {
      if (ev.target.classList.contains('c-nav-mobile')) {
        ctx.emit('hideNavMobile', true)
      }
    }

    watch(
      () => ctx.root.$route.fullPath,
      () => {
        ctx.emit('hideNavMobile', true)
        document.documentElement.style.overflow = 'auto'
      },
    )

    onMounted(async () => {
      await ctx.root.$store.dispatch('content/setCourseUnits', appSlug)
      units.value = ctx.root.$store.getters['content/getCourseUnits'].filter(
        unit => !unit.teacherUnit,
      )
    })

    return {
      units,
      isTeacher,
      userName,
      hidePanel,
    }
  },
}
</script>

<style lang="scss">
.c-nav-mobile {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 100vw;
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  overflow: hidden;
  transition: background-color 0.4s linear 0.1s, left 0.05s linear 0.7s;

  @include bp($mobile-topbar-breakpoint) {
    display: none;
  }

  &--open {
    left: 0;
    background-color: rgba(#000, 0.5);
    transition: background-color 0.4s linear 0.1s, left 0.05s linear 0s;

    .c-nav-mobile__wrapper {
      left: 3rem;
    }
  }

  .c-login__icon {
    margin-left: 1rem;
  }

  .c-login__btn {
    display: none;
  }

  .c-login__menu {
    position: relative;
    display: block !important;

    .c-button {
      margin-top: 1.25rem;
      width: 100%;
      text-align: center;
      border: 0.0625rem solid $nav-mobile-divider;
      background-color: $nav-mobile-bg;
    }
  }
}

.c-nav-mobile__wrapper {
  position: relative;
  left: 100vw;
  padding: 1rem 1.25rem 0 1.25rem;
  width: calc(100% - 5.5rem);
  height: calc(100% - 1rem);
  background-color: $nav-mobile-bg;
  transition: left 0.7s ease;
  overflow-y: auto;
}

.c-logo-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 0.5rem;
  height: 3.25rem;
  position: sticky;
  top: 0;
  background-color: $nav-mobile-bg;
}

.c-logo-mobile__link {
  vertical-align: top;
}

.c-logo-mobile__img {
  height: 2.5rem;
}

.c-nav-mobile__list {
  display: flex;
  flex-direction: column;
  margin: auto 0;
  padding: 0 !important;
  width: 100%;
  list-style: none;
  overflow-y: auto;
}

.c-nav-mobile__item {
  padding: 0;
  border-bottom: 0.0625rem solid $nav-mobile-divider;

  &:target,
  &:focus,
  &:active {
    background: $nav-mobile-bg;
  }

  &:focus {
    outline-offset: -0.125rem;
  }

  &:before {
    display: none;
  }

  &:first-child {
    margin-top: 0.5rem;
  }

  &--unit {
    margin-bottom: 0.625rem;
    font-family: printf, sans-serif;
    border: 0;

    .c-nav-mobile__link {
      color: #fff;
      font-weight: normal;
    }

    &-1 {
      background: $unit-identity-bg;
    }

    &-2 {
      background: $unit-english-bg;
    }

    &-3 {
      background: $unit-citizenship-bg;
    }

    &-4 {
      background: $unit-indigenous-bg;
    }

    &-5 {
      background: $unit-grammar-bg;
    }
  }

  &--login {
    margin-top: auto;
    margin-bottom: 1.5rem;
    padding: 0;
    width: 100%;
    height: auto;
    border: 0;
  }
}

.c-nav-mobile__link {
  display: block;
  padding: 0.75rem 1rem;
  width: calc(100% - 2rem);
  line-height: 1.25rem;
  font-size: 1rem;
  color: $nav-mobile-text;
  font-weight: bold;
  text-decoration: none;

  &[disabled] {
    color: #0007;
    pointer-events: none;
  }
}

.c-topbar__logout-btn {
  font-size: 1rem;
  font-weight: bold;
  color: $nav-mobile-text;
}
</style>
