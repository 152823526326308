<template>
  <FHButton class="c-hamburger" :class="{ 'c-hamburger--open': isOpened }">
    <div class="c-hamburger__icon">
      <span class="c-hamburger-line" />
      <span class="c-hamburger-line" />
      <span class="c-hamburger-line" />
    </div>
    <div class="c-hamburger__text">
      {{ $t('HAMBURGER_MENU') }}
    </div>
  </FHButton>
</template>

<script>
import FHButton from '@forlagshuset/v-button'

export default {
  name: 'Hamburger',

  props: {
    isOpened: Boolean,
  },

  components: {
    FHButton,
  },
}
</script>

<style lang="scss">
.c-hamburger {
  position: relative;
  z-index: 20;
  display: flex;
  margin: 0 -1rem 0 auto;
  padding: 0 2rem 0 1rem;
  width: 7.5rem;
  height: 3.5rem;
  border: 0;
  background-color: $topbar-bg;
  transition: background-color 0.2s;
  transition-delay: 0.5s;

  @media screen and (orientation: landscape) {
    padding: 0 1rem;
  }

  &--open {
    background-color: $nav-mobile-bg;
    transition: background-color 0.2s;
  }

  @include bp-down(small) {
    width: 4.5rem;
  }

  @include bp($mobile-topbar-breakpoint) {
    display: none;
  }

  &:focus {
    outline-offset: -0.125rem;
  }
}

.c-hamburger__icon {
  width: 1.5rem;
}

.c-hamburger-line {
  position: absolute;
  display: block;
  margin-top: 1.125rem;
  width: 1.5rem;
  height: 0.25rem;
  background-color: #000;
  transition: transform 0.2s, top 0.2s, width 0.2s, margin-left 0.2s;

  &:nth-child(1) {
    top: 0;
  }
  &:nth-child(2) {
    top: 0.5rem;
  }
  &:nth-child(3) {
    top: 1rem;
  }

  .c-hamburger--open & {
    &:nth-child(1) {
      top: 0.5rem;
      transform: rotate(-135deg);
    }
    &:nth-child(2) {
      margin-left: 0.5rem;
      width: 0.25rem;
    }
    &:nth-child(3) {
      top: 0.5rem;
      transform: rotate(135deg);
    }
  }
}

.c-hamburger__text {
  display: flex;
  margin: auto 0 auto 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: $nav-mobile-text;

  @include bp-down(small) {
    display: none;
  }
}
</style>
