import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
import vClickOutside from 'v-click-outside'
import 'whatwg-fetch'
import VueGtm from '@gtm-support/vue2-gtm';
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/i18n'
import { mdHtml } from '@/directives'
import './registerServiceWorker'
import 'normalize.css'
import '@/assets/scss/main.scss'

Vue.config.productionTip = false

Vue.directive('md-html', mdHtml)
Vue.use(require('vue-shortkey'))
Vue.use(VueCompositionApi)
Vue.use(vClickOutside)

Vue.use(VueGtm, {
  id: 'GTM-TG9BPWW'
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
