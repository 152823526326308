const SET_COURSE = (state, course) => {
  state.course = course
}

const SET_COURSE_UNITS = (state, units) => {
  state.units = units
}

const SET_UNIT = (state, unit) => {
  state.units.push(unit)
}

const SET_LESSON_PLANS = (state, { lessonPlans, slug }) => {
  state.units.filter(u => {
    if (u.slug === slug) {
      u.lessonPlans = lessonPlans
    }
  })
}

const SET_NEW_TAG = (state, { newTag, slug }) => {
  state.units.filter(u => {
    if (u.slug === slug) {
      u.audiobook = newTag.audios || []
      u.video = newTag.activities || []
      u.text = newTag.articles.sort((a, b) => a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: 'base'
      })) || []
    }
  })
}

export default {
  SET_COURSE,
  SET_COURSE_UNITS,
  SET_UNIT,
  SET_LESSON_PLANS,
  SET_NEW_TAG,
}
