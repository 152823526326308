<template>
  <nav class="c-nav">
    <ul class="c-nav__left">
      <li class="c-nav__item">
        <router-link
          to="/text/echo-welcome-to-echo/welcome-to-echo"
          class="c-nav__link"
        >
          About Echo
        </router-link>
      </li>
      <li v-if="isTeacher" class="c-nav__item">
        <router-link
          to="/fagstoff/echo-teachers-guide-part-1"
          class="c-nav__link"
        >
          Teacher’s guide
        </router-link>
      </li>
    </ul>
    <ul class="c-nav__right">
      <li class="c-nav__item">
        <a
          href="https://reader.dbok.no/#/book/627a65d86d10090015854ce8"
          target="_blank"
          rel="noopener noreferrer"
          class="c-nav__link"
        >
          D-book <i>Mini-grammar</i>
        </a>
      </li>
      <li class="c-nav__item">
        <a
          href="https://reader.dbok.no/#/book/5e87168a190aed001951eb91"
          target="_blank"
          rel="noopener noreferrer"
          class="c-nav__link"
        >
          D-book <i>Texts in English</i>
        </a>
      </li>
      <li class="c-nav__item">
        <a
          href="https://speakup.fagbokforlaget.no/"
          target="_blank"
          rel="noopener noreferrer"
          class="c-nav__link"
        >
          SPEAK UP!
        </a>
      </li>
      <li class="c-nav__item">
        <a
          href="https://writeon.app.fagbokforlaget.no/"
          target="_blank"
          rel="noopener noreferrer"
          class="c-nav__link"
        >
          WRITE ON!
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { computed } from '@vue/composition-api'

export default {
  setup(props, ctx) {
    const isTeacher = computed(
      () => ctx.root.$store.getters['appConfig/isTeacher'],
    )

    return {
      isTeacher,
    }
  },
}
</script>

<style lang="scss">
.c-nav {
  display: none;
  width: 100%;
  margin: auto 0;
  justify-content: space-between;

  @include bp($mobile-topbar-breakpoint) {
    display: flex;
  }
}
.c-nav__left,
.c-nav__right {
  display: flex;
  list-style: none none;
  font-weight: 700;
  li {
    &:before {
      display: none;
    }
  }
}
.c-nav__item {
  margin: 0 1rem;
}
.c-nav__link {
  text-decoration: none;
  color: $color-black;
  font-size: 1rem;
  &[disabled] {
    color: rgba(0, 0, 0, 0.25);
    pointer-events: none;
  }
}
</style>
