<template>
  <div id="app">
    <Topbar />
    <SearchContainer />
    <router-view :key="$route.fullPath" />
    <Footer />
  </div>
</template>

<script>
import Topbar from '@/components/Topbar'
import SearchContainer from '@/components/SearchContainer'
import Footer from '@/components/Footer'
import update from './mixins/update'

export default {
  components: {
    Topbar,
    SearchContainer,
    Footer,
  },
  mixins: [update],
}
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  font-family: $main-font-family;
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;

  * {
    &:focus-visible {
      outline-color: $color-black;
    }
  }
}

table {
  text-align: left;
  border: 1px solid $table-border;
  border-collapse: collapse;
  margin: 1rem 0;

  th,
  td {
    border: 1px solid $table-border;
    padding: 12px;
  }
}
</style>
