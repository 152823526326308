import router from '@/router'

const getToken = () => localStorage.getItem('token') || process.env.VUE_APP_COSS_TOKEN

const DEFAULT_HEADERS = {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + getToken(),
  }
}

const fetchWithAuth = (path, options) => {
  const mergedOptions = Object.assign({}, DEFAULT_HEADERS, options)

  return fetch(`${process.env.VUE_APP_COSS_URL}/${path}`, mergedOptions)
}

const fetchSH = async (param, options) => {
  const res = await fetchWithAuth(param, options)

  if (res.ok) {
    return res.json()
  }

  if (res.status === 401) {
    localStorage.clear()
    router.push('/login')
  }
}

export {
  fetchWithAuth,
  fetchSH
}
