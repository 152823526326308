export default {
  tools: [
    {
      titleLoc: 'SPEAK_UP',
      descriptionLoc: 'SPEAK_UP_DESC',
      image: '/img/speak-up.png',
      link: 'https://speakup.fagbokforlaget.no/',
    },
    {
      titleLoc: 'WRITE_ON',
      descriptionLoc: 'WRITE_ON_DESC',
      image: '/img/write-on.png',
      link: 'https://writeon.app.fagbokforlaget.no/',
    },
  ],
}
